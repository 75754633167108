import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { RCLProvider } from "./RCL";
import { ProfileProvider } from "./Account/context/ProfileProvider";
import { SecurityProvider } from "./Account/context/SecurityProvider";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<RCLProvider
				baseUrl={process.env.REACT_APP_RCL_API}
				env={process.env.REACT_APP_RCL_ENV}
				appSysId={process.env.REACT_APP_RCL_APP_SYS_ID}
			>
				<SecurityProvider>
					<ProfileProvider>
						<HelmetProvider>
							<App />
						</HelmetProvider>
					</ProfileProvider>
				</SecurityProvider>
			</RCLProvider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
