import React from "react";
import Cookie from "universal-cookie";
import { FUNCTIONS_PATH } from "../../Account/config";

const getURLQueries = _query => {
	const allURLData = {
		queries: [],
		hash: "",
	};

	const valuePairs = [];
	const urlHash = window.location.hash;
	const hashExists = !!urlHash;

	const checkForQuery =
		window.location.search.indexOf("?") + 1 < window.location.search.length
			? window.location.href.indexOf("?") + 1
			: false;
	let query;
	if (checkForQuery) {
		const queries = window.location.href
			.replace(urlHash, "")
			.slice(checkForQuery)
			.split("&");
		const tempURLVars = [];

		if (queries?.length > 0) {
			for (let i = 0; i < queries.length; i++) {
				query = queries[i].split("=");
				/* eslint-disable prefer-destructuring */
				valuePairs[query[0]] = query[1];
				tempURLVars[query[0]] = query[1];
			}

			allURLData.queries = tempURLVars;
		} else {
			allURLData.queries = null;
		}
	}

	if (hashExists) {
		allURLData.hash = urlHash;
	}

	if (_query && _query.length > 0) {
		return allURLData.queries[_query];
	}
	return allURLData;
};

const setCookie = (cName, cValue, cPath, cDomain, cExpiry) => {
	const cookie = new Cookie();
	cookie.set(cName, cValue, {
		path: cPath,
		domain: cDomain,
		expires: cExpiry,
	});
};

const getCookie = cName => {
	const cookie = new Cookie();
	return cookie.get(cName);
};

const removeCookie = (cName, cPath, cDomain) => {
	const cookie = new Cookie();
	cookie.remove(cName, { path: cPath, domain: cDomain });
};

const setLanguageCookie = language => {
	// Set cookie date
	const expiration = new Date();
	expiration.setUTCMonth(expiration.getMonth() + 1);

	setCookie("nf_lang", language.toLowerCase(), "/", ".sunwing.ca", expiration);
};

const validateReturnURL = _url => {
	// Handle Base64 URLs
	let cleanedDestination = typeof _url !== "undefined" ? _url : "";
	if (
		cleanedDestination.length > 4 &&
		cleanedDestination.substring(0, 4) !== "http"
	) {
		cleanedDestination = atob(cleanedDestination);

		// Check if it is valid URL, otherwise revert back
		if (cleanedDestination.substring(0, 4) !== "http") {
			cleanedDestination = "";
		}
	}

	return cleanedDestination;
};

const replaceWithJSX = (str, find, replace) => {
	// Recursively iterate through JSX objects for placeholder text replace with components
	const replaceString = (_str, _find, _replace) => {
		try {
			// Attempt to replace delimiter with replacement Component
			const fragments = _str.split(_find);
			const result = [];

			for (let i = 0; i < fragments.length; i++) {
				result.push(<span key={i}>{fragments[i]}</span>);
				if (i < fragments.length - 1)
					result.push(<span key={`${i}-replace`}>{_replace}</span>);
			}

			return result;
		} catch (error) {
			// If failure, return original str value
			return str;
		}
	};

	try {
		const recursiveMap = children =>
			React.Children.map(children, child => {
				if (!React.isValidElement(child)) {
					return replaceString(child, find, replace);
				}

				if (child.props.children) {
					// eslint-disable-next-line no-param-reassign
					child = React.cloneElement(child, {
						children: recursiveMap(child.props.children),
					});
				}

				return child;
			});

		return recursiveMap(str);
	} catch (error) {
		// If failure, return original str value
		return str;
	}
};

const getAccountCreatedStatus = () => {
	const oktaAction = getURLQueries("type_hint");
	const oktaSession = getURLQueries("session_hint");

	return oktaAction === "ACTIVATION" && oktaSession === "AUTHENTICATED";
};

const getTokenStatus = () => {
	const oktaState = getURLQueries("state");
	const oktaOtp = getURLQueries("otp");

	return oktaState || oktaOtp;
};

const getServerDate = async controller => {
	// Get Server date
	const serverDate = await fetch(
		`${window.location.origin}${FUNCTIONS_PATH}/server-date`,
		{ signal: controller.signal },
		{
			headers: { "Content-Type": "application/json" },
			method: "GET",
		}
	)
		.then(res => res.json())
		.then(jsonResponse => jsonResponse?.date)
		.catch(error => {
			console.error("Error: Date is not returned: ", error);
			return null;
		});

	return serverDate ? new Date(serverDate) : new Date();
};

const setLoginType = (oktaToken, socialLogins) => {
	const postBody = {
		email: oktaToken.idToken.claims.email,
		contactType: "customer",
	};

	// Determine the social login type, if applicable
	socialLogins.some(login => {
		if (login.id === oktaToken.idToken.claims.idp) {
			postBody.additionalProperties = [
				{
					code: "LastLoginType",
					value: login.type,
				},
			];
			return true;
		}

		return false;
	});

	// Fallback to Okta login type
	if (postBody.additionalProperties === undefined) {
		postBody.additionalProperties = [
			{
				code: "LastLoginType",
				value: "OKTA",
			},
		];
	}

	fetch(`${process.env.REACT_APP_SWG_APIM_API}/auth/loginType/current`, {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${oktaToken.accessToken.accessToken}`,
		},
		method: "PATCH",
		body: JSON.stringify(postBody),
	})
		.then(res => res)
		.catch(error => {
			console.error("setLoginType:: error: ", error);
		});
};

const removeDiacritics = source => {
	let filteredString = source;

	if (typeof String.prototype.normalize === "function") {
		filteredString = filteredString
			.normalize("NFD")
			.replace(/[\u0300-\u036f]/g, "");
	} else {
		const diacriticDictionary = {
			// Allowed diacritical marked letters
			â: "a",
			à: "a",
			á: "a",
			ç: "c",
			é: "e",
			ê: "e",
			è: "e",
			ë: "e",
			î: "i",
			ï: "i",
			ô: "o",
			û: "u",
			ù: "u",
			ü: "u",
		};

		// Convert all invalid characters to dictionary safe characters
		filteredString = source
			.toLowerCase()
			.replace(
				/[^\w]/g,
				character => diacriticDictionary[character] || character
			);
	}

	return filteredString;
};

const removeHypens = source => source.replace(/-|–|—/g, " ");

const cleanString = source => {
	let filteredString = removeHypens(source);
	filteredString = removeDiacritics(filteredString);
	return filteredString;
};

const updateContext = async (uid, context) => {
	// Update profile context

	const apiRequest = new Request(
		`${process.env.REACT_APP_SWG_APIM_API}/auth/client/current`
	);

	return fetch(apiRequest, {
		headers: {
			"Content-type": "application/json",
		},
		method: "PATCH",
		body: JSON.stringify({
			email: uid,
			contactType: "CUSTOMER",
			additionalProperties: [
				{
					code: "clientId",
					value: context,
				},
			],
		}),
	})
		.then(async res => {
			if (!res.ok) {
				if (res.status !== 204) {
					throw new Error(`${res.status} ${res.statusText}`);
				}
			}
			return res;
		})
		.catch(error => {
			console.error("updateContext method failed: ", error);
			return error;
		});
};

export default getURLQueries;
export {
	getURLQueries,
	setLanguageCookie,
	setCookie,
	getCookie,
	removeCookie,
	validateReturnURL,
	replaceWithJSX,
	getAccountCreatedStatus,
	getServerDate,
	setLoginType,
	cleanString,
	getTokenStatus,
	updateContext,
};
