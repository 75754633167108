import React from "react";
import PropTypes from "prop-types";
import { Button } from "@sunwing/components";
import { routingPaths } from "./config";
import useGatsbyAuth from "./hook/useGatsbyAuth";
import { RCL } from "../RCL";
import styles from "../Header/Header.module.scss";

const SignOutUser = (language, authService) => {
	// Signs out of Okta session, removes localStorage & invalidates SID cookie
	authService.signOut({
		postLogoutRedirectUri: `${window.location.origin}/${language}/${routingPaths[language].login}`,
	});
};

// Basic component with logout button
const Logout = ({ language, onClickHandler }) => {
	const { authService } = useGatsbyAuth();

	const logout = async () => {
		SignOutUser(language, authService);
	};

	return (
		<Button
			className={styles.logout}
			outline={false}
			type="button"
			noStyle={true}
			theme="primary"
			onClick={() => {
				if (typeof onClickHandler === "function") {
					onClickHandler();
				}
				logout();
			}}
		>
			<RCL searchKey="okta-header-menu-sign-out" />
		</Button>
	);
};

Logout.propTypes = {
	language: PropTypes.oneOf(["en", "fr"]),
	onClickHandler: PropTypes.func,
};

Logout.defaultProps = {
	language: "en",
	onClickHandler: null,
};

export default Logout;
export { Logout, SignOutUser };
