import React, {
	createContext,
	useState,
	useMemo,
	useCallback,
	useEffect,
	useRef,
} from "react";
import PropTypes from "prop-types";
import * as dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import useGatsbyAuth from "../hook/useGatsbyAuth";
import {
	getAccountCreatedStatus,
	getServerDate,
} from "../../utils/helpers/helper";
import { routingPaths } from "../config";
import { useLocale } from "../../RCL";
import { SignOutUser } from "../Logout";

const ProfileContext = createContext();

let timer;

const ProfileProvider = ({ children }) => {
	const { lang } = useLocale();
	const language = lang?.toLowerCase();

	const { authState, authService } = useGatsbyAuth();
	const navigate = useNavigate();
	const [isUserLoggedIn, setLoggedIn] = useState(false);
	const [profile, setProfile] = useState({});
	const isAccountCreated = useRef(getAccountCreatedStatus());
	const [enableSSO, setEnableSSO] = useState(false);
	const [serverDate, setServerDate] = useState();
	const [initDateTimer, setInitDateTimer] = useState();
	const ticks = useRef(0);

	// Determine user verification state
	const [isVerified, setIsVerified] = useState(false);

	// Determine if user has been stamped within registration window
	const [isValidUser, setIsValidUser] = useState();

	const [init, setInit] = useState(true);

	const updateProfile = useCallback(
		attributeObject => {
			const newState = Object.assign(profile, attributeObject);
			localStorage.setItem("profile", btoa(JSON.stringify(newState)));

			setProfile({
				...profile,
				...attributeObject,
			});
		},
		[profile]
	);

	const valueProps = useMemo(
		() => ({
			profile,
			updateProfile,
			isUserLoggedIn,
			setLoggedIn,
			enableSSO,
			setEnableSSO,
			serverDate,
			setServerDate,
			isVerified,
			setIsVerified,
			isValidUser,
		}),
		[
			profile,
			updateProfile,
			isUserLoggedIn,
			setLoggedIn,
			enableSSO,
			setEnableSSO,
			serverDate,
			setServerDate,
			isVerified,
			setIsVerified,
			isValidUser,
		]
	);

	const validateUser = userProfile => {
		// Determine if registration is still available
		const signupCutOffDate = new Date(process.env.REACT_APP_SIGNUP_CUTOFF);
		const isSignupValid = signupCutOffDate?.getTime() >= serverDate?.getTime();

		let isValidAffiliateCode = false;

		// Add an affiliate code for this application
		const affiliateArray = userProfile?.affiliateCodes ?? [];
		if (
			affiliateArray.includes(window.btoa(process.env.REACT_APP_PROGRAM_ID))
		) {
			isValidAffiliateCode = true;
		}

		if ((!isSignupValid && isValidAffiliateCode) || isSignupValid) {
			// valid session
			setIsValidUser(true);
			setIsVerified(true);
		} else {
			// invalid session
			setIsValidUser(false);
		}
	};

	useEffect(() => {
		const controller = new AbortController();

		(async () => {
			await getServerDate(controller).then(date => {
				// Determine if access is still available
				const accessCutOffDate = new Date(process.env.REACT_APP_ACCESS_CUTOFF);
				const isAccessValid = accessCutOffDate.getTime() > date?.getTime();
				if (authState?.isAuthenticated) {
					setEnableSSO(isAccessValid);
				}
				setServerDate(date);
			});
		})();

		return () => {
			controller.abort();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			authService &&
			init &&
			((!isValidUser && !isAccountCreated?.current && enableSSO) ||
				(!authState?.isAuthenticated && enableSSO))
		) {
			(async () => {
				if (serverDate && initDateTimer === undefined) {
					if (timer !== undefined) {
						window.clearInterval(timer);
					}
					setInitDateTimer(true);
				}

				await authService.session
					.get()
					.then(async data => {
						setInit(false);

						if (data.status === "ACTIVE") {
							const userSession = await data
								?.user()
								.then(user => user)
								.catch(error => {
									console.error("Session user error: ", { error });
								});

							try {
								const { profile: userProfile } = userSession;

								if (userProfile) {
									validateUser(userProfile);

									await authService.token.getWithoutPrompt().then(res => {
										const { tokens } = res;
										authService.tokenManager.setTokens(tokens);
										setLoggedIn(true);

										updateProfile({
											firstName: tokens.idToken.claims.firstName ?? "",
											lastName: tokens.idToken.claims.lastName ?? "",
											agentGlobalId: tokens.idToken.claims.agentGlobalId ?? "",
										});
									});
								}
							} catch (error) {
								console.error("Session profile error: ", { error });
								navigate(`/${language}/${routingPaths[language].callback}`);
							}
						}
					})
					.catch(error => {
						console.error("Session get error: ", { error });
					});
			})();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		authService,
		authState,
		enableSSO,
		isAccountCreated,
		isValidUser,
		routingPaths,
	]);

	// Setup profile data from localStorage
	useEffect(() => {
		try {
			setProfile(
				localStorage.getItem("profile")
					? JSON.parse(atob(localStorage.getItem("profile")))
					: {
							firstName: "",
							lastName: "",
					  }
			);
		} catch (error) {
			localStorage.removeItem("profile");
		}
	}, []);

	useEffect(() => {
		if (initDateTimer) {
			setInitDateTimer(false);
			// Count the time passed since server time was provided and
			// handle sign out of user if session time has passed site access cut-off date/time
			timer = setInterval(() => {
				ticks.current += 1;

				const newDate = dayjs(serverDate)
					.add(ticks.current, "seconds")
					.utc()
					.toDate();

				// Determine if access is still available
				const accessCutOffDate = new Date(process.env.REACT_APP_ACCESS_CUTOFF);
				const isAccessValid = accessCutOffDate.getTime() > newDate?.getTime();

				if (!isAccessValid && isUserLoggedIn) {
					window.clearInterval(timer);
					SignOutUser(language, authService);
				}
			}, 1000);
		}
	}, [authService, initDateTimer, isUserLoggedIn, language, serverDate]);

	return (
		<ProfileContext.Provider value={valueProps}>
			{children}
		</ProfileContext.Provider>
	);
};

ProfileProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

ProfileProvider.defaultProps = {
	children: undefined,
};

export default ProfileProvider;
export { ProfileProvider, ProfileContext };
