import React, { useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./BookingSearchBox.module.scss";

let isLoading = false;

const BookingSearchBox = ({ lang, type, mode, tabs }) => {
	useEffect(() => {
		const bsbScript = document.createElement("script");

		if (!isLoading) {
			isLoading = true;
			bsbScript.src = process.env.REACT_APP_BSB_JS;
			bsbScript.async = true;
			bsbScript.defer = true;
			document.body.appendChild(bsbScript);
		}

		return () => {
			if (bsbScript || isLoading) {
				if (window.WEB_CDN_BSB && window.WEB_CDN_BSB.destroy) {
					window.WEB_CDN_BSB.destroy();
				}
				bsbScript.parentNode.removeChild(bsbScript);
				isLoading = false;
			}
		};
	}, []);

	return (
		<div
			className={cx(styles.bar, styles[type === "other" ? "packages" : type])}
		>
			<div
				id="booking-search-box-wrapper"
				className={styles.placeholder}
				data-lang={lang}
				data-alias="qba"
				data-type={type}
				data-mode={mode}
				data-tabs={tabs}
			/>
		</div>
	);
};

BookingSearchBox.propTypes = {
	lang: PropTypes.oneOf(["en", "fr"]).isRequired,
	type: PropTypes.oneOf(["packages", "flights", "hotels", "cruises", "other"]),
	mode: PropTypes.oneOf(["search", "tabs"]),
	tabs: PropTypes.string,
};

BookingSearchBox.defaultProps = {
	type: "packages",
	mode: "search",
	tabs: "packages,flights,cruises",
};

export default BookingSearchBox;
export { BookingSearchBox };
