import React, { useEffect, useState, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Section, Alert, Icon, Button } from "@sunwing/components";
import "@sunwing/components/dist/index.css";
import { RCL as useTranslation } from "../RCL";
import { routingPaths, socialLogins, segmentRouteUpdate } from "./config";
import {
	getURLQueries,
	setLoginType,
	replaceWithJSX,
} from "../utils/helpers/helper";
import useGatsbyAuth from "./hook/useGatsbyAuth";
import { DotsLoader } from "../Common/Loader";
import { SignOutUser } from "./Logout";
import { ProfileContext } from "./context/ProfileProvider";
import styles from "./Callback.module.scss";

let idpLoginMessage = null;
let idpLoginError = null;
let isInitialized = false;

const Callback = ({ language, isValidUser }) => {
	const navigate = useNavigate();
	const { authService, authState } = useGatsbyAuth();
	const [isLoading, setLoading] = useState(true);
	const [isValidSession, setValidSession] = useState(false);
	const [redirectState, setRedirectState] = useState(false);
	const [profile, setProfile] = useState(null);
	const [isValidLogin, setIsValidLogin] = useState(true);
	const programId = window.btoa(process.env.REACT_APP_PROGRAM_ID);
	const { updateProfile, setIsVerified } = useContext(ProfileContext);

	dayjs.locale(language);
	dayjs.extend(utc);
	const dateFormat =
		language === "fr"
			? "dddd D MMM YYYY à HH [h] mm"
			: "ddd, MMM D, YYYY h:mm A";

	const dictionary = {
		"okta-idp-login-error-email-missing": useTranslation({
			searchKey: "okta-idp-login-error-email-missing",
		}),
		"okta-idp-login-error-permission-message": useTranslation({
			searchKey: "okta-idp-login-error-permission-message",
		}),
		"qba-button-non-membership-proceed": useTranslation({
			searchKey: "qba-button-non-membership-proceed",
		}),
		"qba-cut-off-message": useTranslation({
			searchKey: "qba-cut-off-message",
		}),
	};

	const signOut = useCallback(() => {
		SignOutUser(language, authService);
	}, [language, authService]);

	const handleErrors = useCallback(
		(staticMessage, error) => {
			// Catch any login failures here and redirect to login page
			console.error(`${staticMessage} ${error}`);

			if (idpLoginError === "access_denied") {
				const cleanedError = decodeURIComponent(idpLoginMessage).replace(
					/\+/g,
					" "
				);

				if (
					cleanedError.indexOf("Missing field: 'email'") > -1 ||
					cleanedError.indexOf("missing: 'email'") > -1
				) {
					// Clean URL
					if (typeof window.history.pushState === "function") {
						window.history.pushState("", "/", window.location.pathname);
					} else {
						window.location.hash = "";
					}

					// Display error message
					setLoading(false);
				} else {
					// User cancelled idp login
					signOut();
				}
			} else if (!isValidSession && !redirectState) {
				// General error return to login page
				signOut();
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[authService, language, routingPaths.base, routingPaths.login]
	);

	useEffect(() => {
		(async () => {
			if (authService) {
				if (!isValidSession && !redirectState) {
					// Capture idp error responses
					idpLoginMessage = getURLQueries("error_description");
					idpLoginError = getURLQueries("error");

					await authService
						.storeTokensFromRedirect()
						.then(() => {
							setValidSession(true);
						})
						.catch(error => {
							handleErrors("Callback storeTokensFromRedirect error:", error);
						})
						.finally(() => {
							setRedirectState(true);
						});
				} else if (
					authState?.isAuthenticated &&
					isValidSession &&
					redirectState &&
					!isInitialized
				) {
					isInitialized = true;

					// Perform default Consumer login redirect flow
					await authService.token
						.getWithoutPrompt()
						.then(res => {
							const { tokens } = res;
							authService.tokenManager.setTokens(tokens);

							setProfile(tokens.idToken.claims);

							// Attempt LoginType update
							try {
								setLoginType(tokens, socialLogins);
							} catch (error) {
								console.error("setLoginType: error: ", error);
							}
						})
						.catch(error => {
							handleErrors("Callback getWithoutPrompt error:", error);
						});
				}
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		authService,
		authState,
		isValidSession,
		language,
		profile,
		redirectState,
	]);

	const getProfileData = useCallback(
		async accessToken =>
			fetch(`${process.env.REACT_APP_SWG_APIM_API}/profile`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${accessToken}`,
				},
				method: "GET",
			})
				.then(res => {
					if (!res.ok) {
						throw res.error;
					}
					return res.json();
				})
				.then(res => res)
				.catch(err => {
					console.error("getProfileData:: error: ", err);
					return undefined;
				}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	// Handle the case where a social login is missing global id and / or locale via bypassing the verification step
	const updateProfileLocale = async (accessToken, profileData) => {
		if (profileData?.contactPreferences) {
			// eslint-disable-next-line no-param-reassign
			profileData.contactPreferences.languageCode = `${language}_CA`;
		} else {
			// eslint-disable-next-line no-param-reassign
			profileData.contactPreferences = {
				languageCode: `${language}_CA`,
			};
		}

		const updatedProfileData = {
			...profileData,
		};

		return fetch(`${process.env.REACT_APP_SWG_APIM_API}/profile?rt=true`, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify(updatedProfileData),
			method: "PUT",
		})
			.then(res => {
				if (!res.ok) {
					throw res.error;
				}
				return res.json();
			})
			.then(() => true)
			.catch(() => false);
	};

	useEffect(() => {
		(async () => {
			if (isValidUser !== undefined && isInitialized) {
				await authService.session.get().then(async session => {
					const userSession = await session.user();
					const { profile: userProfile } = userSession;
					const isMissingGID = !authState?.accessToken?.claims?.gid;
					const isMissingLocale = !userProfile?.locale;

					if (isMissingGID || isMissingLocale) {
						// Update profile DB & Okta to ensure global Id is assigned
						const profileData = await getProfileData(
							authState.accessToken.accessToken
						);

						if (isMissingLocale && profileData) {
							// Update profile DB & Okta to ensure language and locale is set
							await updateProfileLocale(
								authState.accessToken.accessToken,
								profileData
							);
						}
					}

					if (isValidUser) {
						setTimeout(() => {
							if (userProfile.agentGlobalId) {
								navigate(`/${language}/${routingPaths[language].agent}`);
							} else if (userProfile?.affiliateCodes?.includes(programId)) {
								setIsVerified(true);
								segmentRouteUpdate();
								navigate(`/${language}/${routingPaths[language].home}`);
							} else {
								setIsVerified(false);
								updateProfile({
									userStatus: "invalid-user-EAU0002",
								});

								signOut();
							}
						}, 100);
					} else {
						// Block login past server date
						setLoading(false);
						setIsValidLogin(isValidUser);
					}
				});
			} else if (isInitialized && isValidUser === undefined) {
				// Handle scenario when sign-in occurs past the access date
				signOut();
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authService.session, isValidUser, language, isInitialized]);

	let message = dictionary["qba-cut-off-message"];

	message = replaceWithJSX(
		message,
		"{SIGNUP_DATE_CUTOFF}",
		dayjs
			.utc(process.env.REACT_APP_SIGNUP_CUTOFF)
			.utcOffset(-4, false)
			.format(dateFormat)
	);

	return (
		<>
			{!isLoading && isValidLogin && (
				<Section>
					<Alert
						variant="danger"
						textWrapper={false}
						className={styles.alertMessage}
						dismissible={false}
					>
						<div className={styles.headingContainer}>
							<Icon className={styles.headingIcon} name="close" />
							<div className={styles.headingMessage}>
								{dictionary["okta-idp-login-error-email-missing"]}
								<br />
								{dictionary["okta-idp-login-error-permission-message"]}
								<br />
								<a href={`/${language}`}>
									{dictionary["okta-idp-login-error-return-message"]}
								</a>
							</div>
						</div>
					</Alert>
				</Section>
			)}

			{isLoading && isValidLogin && (
				<Section className={styles.parent}>
					<DotsLoader className={styles.loader} />
				</Section>
			)}

			{!isValidLogin && (
				<Section>
					<Alert
						variant="danger"
						textWrapper={false}
						className={styles.alertMessage}
						dismissible={false}
					>
						<div className={styles.headingContainer}>
							<Icon className={styles.headingIcon} name="close" />
							<div className={styles.headingMessage}>
								{message}
								<a href={`/${language}`}>
									{dictionary["okta-idp-login-error-return-message"]}
								</a>
								<Button
									className={styles.button}
									theme="none"
									onClick={() => {
										authService.closeSession();
										window.open(process.env.REACT_APP_SWG_DOMAIN, "_parent");
									}}
								>
									{dictionary["qba-button-non-membership-proceed"]}
								</Button>
							</div>
						</div>
					</Alert>
				</Section>
			)}
		</>
	);
};

Callback.propTypes = {
	language: PropTypes.oneOf(["en", "fr"]).isRequired,
	isValidUser: PropTypes.bool,
};

Callback.defaultProps = {
	isValidUser: undefined,
};

export default Callback;
export { Callback };
