import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./Spinner.module.scss";

const SpinnerLoader = ({ className }) => (
	<div className={cx(className, styles.spinner)}>
		<div />
		<div />
		<div />
		<div />
		<div />
		<div />
		<div />
		<div />
		<div />
		<div />
		<div />
		<div />
	</div>
);

SpinnerLoader.propTypes = {
	className: PropTypes.string,
};

SpinnerLoader.defaultProps = {
	className: undefined,
};

export default SpinnerLoader;
export { SpinnerLoader };
