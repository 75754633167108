import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { Heading, Section } from "@sunwing/components";
import { Link } from "react-router-dom";
import useGatsbyAuth from "../Account/hook/useGatsbyAuth";
import { RCL as useTranslation } from "../RCL";
import { routingPaths, segmentRouteUpdate } from "../Account/config";
import styles from "./404.module.scss";

const ErrorPage = ({ lang }) => {
	const { authState } = useGatsbyAuth();

	const dictionary = {
		"qba-header-meta-title-error": useTranslation({
			searchKey: "qba-header-meta-title-error",
		}),
		"qba-header-meta-description-error": useTranslation({
			searchKey: "qba-header-meta-description-error",
		}),
		"qba-error-page-something-wrong": useTranslation({
			searchKey: "qba-error-page-something-wrong",
		}),
		"qba-error-page-return-home-1": useTranslation({
			searchKey: "qba-error-page-return-home-1",
		}),
		"qba-error-page-return-home-2": useTranslation({
			searchKey: "qba-error-page-return-home-2",
		}),
	};

	return (
		<>
			<Helmet>
				<meta name="robots" content="noindex, nofollow" />
				<meta
					property="description"
					content={dictionary["qba-header-meta-description-error"]}
				/>
				<meta
					property="og:title"
					content={dictionary["qba-header-meta-title-error"]}
				/>
				<meta
					property="og:description"
					content={dictionary["qba-header-meta-description-error"]}
				/>
				<title>{dictionary["qba-header-meta-title-error"]}</title>
			</Helmet>
			<Section className={styles.errorPage} theme="none">
				<img
					className={styles.errorPageImg}
					src="https://assets.sunwingtravelgroup.com/image/upload/g_auto,c_lfill,q_auto,f_auto,w_1600,h_652/v1615398471/Dev/404-bg"
					alt=""
					aria-hidden="true"
				/>
				<Heading as="h1" className={styles.errorPageHeading}>
					<span>O</span>
					<span>O</span>
					<span>O</span>
					<span>P</span>
					<span>S</span>
					<span>.</span>
					<span>.</span>
					<span>.</span>
				</Heading>
				<Heading as="p" size="h2" className={styles.errorPageSubheading}>
					{dictionary["qba-error-page-something-wrong"]}
				</Heading>
				<p>
					{dictionary["qba-error-page-return-home-1"]}
					<Link
						onClick={() => {
							segmentRouteUpdate();
						}}
						to={
							authState?.isAuthenticated
								? `${lang}/${routingPaths[lang].home}`
								: `${lang}/${routingPaths[lang].login}`
						}
						className={styles.errorPageReturnLink}
					>
						{dictionary["qba-error-page-return-home-2"]}
					</Link>
				</p>
			</Section>
		</>
	);
};

ErrorPage.propTypes = {
	lang: PropTypes.string.isRequired,
};

export default ErrorPage;
export { ErrorPage };
