const FUNCTIONS_PATH = "/.netlify/functions";

const config = {
	domain: process.env.REACT_APP_OKTA_DOMAIN,
	issuer: process.env.REACT_APP_OKTA_ISSUER,
	clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
	scopes: ["openid", "profile", "email", "brand:swg"],
	responseType: ["token", "id_token"],
	pkce: true,
	disableHttpsCheck: process.env.REACT_APP_OKTA_DISABLE_HTTPS_CHECK,
};

const routingPaths = {
	en: {
		login: "",
		home: "home",
		profile: "profile",
		claim: "contact-us",
		callback: "login/callback",
		agent: "agent",
		help: "guide",
	},
	fr: {
		login: "",
		home: "accueil",
		profile: "profil",
		claim: "contactez-nous",
		callback: "login/callback",
		agent: "agent",
		help: "guide",
	},
};

// Check for each IDP variable for disabling via Netlify
const socialLogins = [];

const FACEBOOK_IDP = process.env.REACT_APP_OKTA_FACEBOOK_IDP;

if (FACEBOOK_IDP && FACEBOOK_IDP !== "null") {
	socialLogins.push({
		id: FACEBOOK_IDP,
		type: "FACEBOOK",
		notify: true,
	});
}

const GOOGLE_IDP = process.env.REACT_APP_OKTA_GOOGLE_IDP;

if (GOOGLE_IDP && GOOGLE_IDP !== "null") {
	socialLogins.push({
		id: GOOGLE_IDP,
		type: "GOOGLE",
		notify: false,
	});
}

const LINKEDIN_IDP = process.env.REACT_APP_OKTA_LINKEDIN_IDP;

if (LINKEDIN_IDP && LINKEDIN_IDP !== "null") {
	socialLogins.push({
		id: LINKEDIN_IDP,
		type: "LINKEDIN",
		notify: false,
	});
}

const APPLE_IDP = process.env.REACT_APP_OKTA_APPLE_IDP;

if (APPLE_IDP && APPLE_IDP !== "null") {
	socialLogins.push({
		id: APPLE_IDP,
		type: "APPLE",
		notify: true,
	});
}

const SEGMENT_CONTEXT = {
	context: {
		app: {
			code: process.env.REACT_APP_CREATED_SOURCE,
			name: process.env.REACT_APP_SEGMENT_NAME,
		},
	},
	campaign: {
		brand_code: "SWG",
		code: process.env.REACT_APP_SEGMENT_SLUG,
	},
};

const segmentRouteUpdate = () => {
	setTimeout(() => {
		window.analytics.page({
			brand_code: "SWG",
			app_code: process.env.REACT_APP_CREATED_SOURCE,
		});
	}, 500);
};

module.exports = {
	config,
	socialLogins,
	routingPaths,
	FUNCTIONS_PATH,
	SEGMENT_CONTEXT,
	segmentRouteUpdate,
};
