import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { renderReactChildrenWithData } from "../../utils/render-react-children-with-data";
import styles from "./InputWrapper.module.scss";

const InputWrapper = React.forwardRef(
	(
		{ id, label, children, className, error, onFocus, onBlur, theme, errorMsg },
		ref
	) => {
		const [isFocused, setIsFocused] = useState(false);

		const handleOnFocus = (overrideCondition = false) => {
			if (
				onFocus() ||
				(typeof overrideCondition === "boolean" && overrideCondition)
			) {
				setIsFocused(true);
			}
		};

		const handleOnBlur = (overrideCondition = false) => {
			if (
				onBlur() ||
				(typeof overrideCondition === "boolean" && overrideCondition)
			) {
				setIsFocused(false);
			}
		};

		return (
			<>
				<label
					ref={ref}
					id={id}
					htmlFor={`${id}-input`}
					className={cx(
						styles.container,
						className,
						isFocused && styles.focused,
						error && styles.error,
						theme === "filters" && styles.filters
					)}
				>
					<span
						className={cx(
							styles.label,
							theme === "filters" && styles.filtersLabel
						)}
					>
						{label}
					</span>
					{renderReactChildrenWithData(children, {
						className: styles.input,
						isFocused,
						handleOnFocus,
						handleOnBlur,
					})}
					{theme === "default" && (
						<fieldset aria-hidden="true" className={styles.fieldset}>
							<legend>
								<span>{label}</span>
							</legend>
						</fieldset>
					)}
				</label>
				{error && errorMsg && (
					<span role="alert" className={styles.errorMessage}>
						{errorMsg}
					</span>
				)}
			</>
		);
	}
);

InputWrapper.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
	className: PropTypes.string,
	error: PropTypes.bool,
	errorMsg: PropTypes.string,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	theme: PropTypes.oneOf(["default", "filters"]),
};

InputWrapper.defaultProps = {
	className: undefined,
	error: false,
	errorMsg: undefined,
	onFocus: () => true,
	onBlur: () => true,
	theme: "default",
};

export default InputWrapper;
export { InputWrapper };
