import React, { createContext, useReducer, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { setLanguageCookie } from "../utils/helpers/helper";
import { DotsLoader } from "../Common/Loader";

const RCLContext = createContext({ lang: undefined, status: "LOADING" });

const rclReducer = (state, action) => {
	switch (action.type?.toUpperCase()) {
		case "SET-DICTIONARY":
			return {
				...state,
				status: "READY",
				dictionary: action.payload.dictionary,
			};
		case "UPDATE-LANG":
			return {
				...state,
				lang: action.payload.lang.toUpperCase(),
			};
		case "UPDATE-STATUS":
			return {
				...state,
				status: action.payload.status,
			};
		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
};

const RCLProvider = ({ baseUrl, env, appSysId, children }) => {
	const location = useLocation();
	const history = useNavigate();

	const [state, dispatch] = useReducer(rclReducer, {
		status: "LOADING",
		lang: undefined,
		dictionary: {},
	});

	const handleLang = () => {
		// Handle URL rewrite with search query and hash support
		if (location.pathname === "/fr") {
			history(`/fr/${location.search}${location.hash}`);
			return false;
		}

		if (location.pathname === "/en") {
			history(`/en/${location.search}${location.hash}`);
			return false;
		}

		return true;
	};

	useEffect(() => {
		if (handleLang()) {
			const urlLang = location.pathname.indexOf("/fr/") === 0 ? "fr" : "en";

			setLanguageCookie(urlLang);

			dispatch({
				type: "UPDATE-LANG",
				payload: {
					lang: urlLang,
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history]);

	useEffect(() => {
		if (state.lang) {
			(async () => {
				// set language cookie?
				if (state.dictionary[state.lang]) {
					return false;
				}

				dispatch({
					type: "UPDATE-STATUS",
					payload: {
						status: "LOADING",
					},
				});

				let lang = "en-US";

				if (state.lang.toLowerCase() === "fr") {
					lang = "fr";
				}

				const data = await fetch(
					`${baseUrl}?content_type=applicationResources&environment=${env}&fields.application.sys.id[match]=${appSysId}&limit=1000&locale=${lang}`,
					{
						method: "GET",
						headers: {
							"content-type": "application/json",
						},
					}
				).then(res => res.json());

				const _dictionary = { ...state.dictionary };

				for (let i = 0; i < data.items.length; i++) {
					const item = data.items[i];

					// Create empty object if lang has not been initialized in the dictionary
					if (!_dictionary[state.lang]) {
						_dictionary[state.lang] = {};
					}

					_dictionary[state.lang][item.fields.key] = {
						parseAsMarkdown: item.fields.parseAsMarkdown,
						value: item.fields?.value,
					};
				}

				dispatch({
					type: "SET-DICTIONARY",
					payload: {
						dictionary: _dictionary,
					},
				});
				return _dictionary;
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.lang]);

	const value = useMemo(() => ({ state, dispatch }), [state]);

	return (
		<RCLContext.Provider value={value}>
			{value.state.status === "LOADING" ? (
				<div className="loader">
					<DotsLoader />
				</div>
			) : (
				children
			)}
		</RCLContext.Provider>
	);
};

RCLProvider.propTypes = {
	baseUrl: PropTypes.string.isRequired,
	env: PropTypes.string.isRequired,
	appSysId: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

RCLProvider.defaultProps = {
	children: undefined,
};

export default RCLProvider;
export { RCLProvider, RCLContext };
