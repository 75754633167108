import React from "react";
import { Helmet } from "react-helmet-async";
import { Section } from "@sunwing/components";
import { RCL, RCL as useTranslation } from "../RCL";

import "./Help.css";

const Help = () => {
	const dictionary = {
		"qba-header-meta-title-help": useTranslation({
			searchKey: "qba-header-meta-title-help",
		}),
		"qba-header-meta-description-help": useTranslation({
			searchKey: "qba-header-meta-description-help",
		}),
		"guide-title": useTranslation({ searchKey: "guide-title" }),
	};

	return (
		<>
			<Helmet>
				<meta name="robots" content="noindex, nofollow" />
				<meta
					property="description"
					content={dictionary["qba-header-meta-description-help"]}
				/>
				<meta
					property="og:title"
					content={dictionary["qba-header-meta-title-help"]}
				/>
				<meta
					property="og:description"
					content={dictionary["qba-header-meta-description-help"]}
				/>
				<title>{dictionary["qba-header-meta-title-help"]}</title>
			</Helmet>
			<Section
				theme="none"
				title={dictionary["guide-title"]}
				titleAs="h1"
				titleSize="h2"
				titleUppercase={true}
				titleWeight="bold"
				titleAlignment="left"
			>
				<div className="guide">
					<RCL searchKey="guide-intro" />

					<div id="facebook" className="socialIdpSection">
						<RCL searchKey="facebook-guide-content" />
					</div>

					<div id="apple" className="socialIdpSection">
						<RCL searchKey="apple-guide-content" />
					</div>
				</div>
			</Section>
		</>
	);
};

export default Help;
export { Help };
