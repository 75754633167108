import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { Section } from "@sunwing/components";
import { ProfileContext } from "../Account/context/ProfileProvider";
import { RCL as useTranslation } from "../RCL";
import { IframeResizer } from "../utils/iframeResizer";

const Claim = ({ language }) => {
	const { profile } = useContext(ProfileContext);

	const iframeSrc =
		language === "fr"
			? process.env.REACT_APP_FA_FORM_FR
			: process.env.REACT_APP_FA_FORM_EN;

	const dictionary = {
		"qba-header-meta-title-claim": useTranslation({
			searchKey: "qba-header-meta-title-claim",
		}),
		"qba-header-meta-description-claim": useTranslation({
			searchKey: "qba-header-meta-description-claim",
		}),
		"qba-claim-iframe-title": useTranslation({
			searchKey: "qba-claim-iframe-title",
		}),
	};

	return (
		<>
			<Helmet>
				<meta name="robots" content="noindex, nofollow" />
				<meta
					property="description"
					content={dictionary["qba-header-meta-description-claim"]}
				/>
				<meta
					property="og:title"
					content={dictionary["qba-header-meta-title-claim"]}
				/>
				<meta
					property="og:description"
					content={dictionary["qba-header-meta-description-claim"]}
				/>
				<title>{dictionary["qba-header-meta-title-claim"]}</title>
			</Helmet>
			<Section theme="none">
				{iframeSrc && (
					<iframe
						className="fa-iframe"
						src={`${iframeSrc}?firstName=${profile?.firstName || ""}&lastName=${
							profile?.lastName || ""
						}&email=${profile?.email || ""}&birthDate=${
							profile?.birthDate || ""
						}`}
						title={dictionary["qba-claim-iframe-title"]}
						frameBorder="0"
						width="100%"
						height="0"
						onLoad={() => {
							IframeResizer(window);
						}}
					/>
				)}
			</Section>
		</>
	);
};

Claim.propTypes = {
	language: PropTypes.oneOf(["en", "fr"]).isRequired,
};

export default Claim;
export { Claim };
