import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { Icon } from "@sunwing/components";
import "@sunwing/components/dist/index.css";
import * as dayjs from "dayjs";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import en from "rc-picker/lib/locale/en_US";
import fr from "rc-picker/lib/locale/fr_FR";
import RCDatePicker from "rc-picker";
import baseStyles from "./base.module.scss";
import "./styles.scss";

const DatePicker = React.forwardRef(
	(
		{
			inputRef,
			className,
			locale,
			format,
			selectedDate,
			minDate,
			maxDate,
			disabled,
			onFocus,
			onBlur,
			onChange,
			isDefaultValue,
			isOpenUI,
			placeholder,
			id,
			onMouseOver,
		},
		ref
	) => {
		const [isOpened, setIsOpened] = useState(false);
		const datePickerRef = useRef(ref);

		useEffect(() => {
			if (isOpened) {
				disableBodyScroll(datePickerRef.current);
			} else {
				clearAllBodyScrollLocks();
			}
		}, [isOpened, ref]);

		return (
			<RCDatePicker
				id={id}
				placeholder={placeholder}
				ref={datePickerRef}
				prefixCls="swgPicker"
				generateConfig={dayjsGenerateConfig}
				picker="date"
				locale={
					locale === "fr"
						? { ...fr, monthFormat: "MMMM" }
						: { ...en, monthFormat: "MMMM" }
				}
				format={format}
				// Replicate default month cell render but with different formatting
				// eslint-disable-next-line react/no-unstable-nested-components
				monthCellRender={month => (
					<div className="swgPicker-cell-inner">{month.format("MMM")}</div>
				)}
				className={cx(
					className,
					baseStyles.inputGroup,
					isOpened && isOpenUI && baseStyles.inputGroupOpen
				)}
				value={selectedDate}
				defaultValue={isDefaultValue ? selectedDate : ""}
				open={isOpened}
				disabled={disabled}
				onMouseEnter={() => onMouseOver(true)}
				onMouseLeave={() => onMouseOver(false)}
				onFocus={onFocus}
				onBlur={onBlur}
				onChange={onChange}
				onOpenChange={open => {
					setIsOpened(open);
					if (open) {
						onFocus();
					}
				}}
				getPopupContainer={() => inputRef?.current}
				disabledDate={current =>
					current.isBefore(minDate || dayjs().startOf("day")) ||
					current.isAfter(maxDate || dayjs().startOf("day").add(365, "days"))
				}
				allowClear={false}
				inputReadOnly={true}
				clearIcon={null}
				suffixIcon={
					<Icon
						name="calendar"
						className={cx("calendarIcon")}
						onClick={() => {
							if (!disabled) {
								setIsOpened(!isOpened);
								if (!isOpened) {
									onFocus();
								}
							}
						}}
					/>
				}
				superPrevIcon={
					<Icon
						name="arrow-thick"
						className={cx("navigationIcon", "navigationIconPrev")}
					/>
				}
				superNextIcon={
					<Icon
						name="arrow-thick"
						className={cx("navigationIcon", "navigationIconNext")}
					/>
				}
				prevIcon={
					<Icon
						name="arrow-thick"
						className={cx("navigationIcon", "navigationIconPrev")}
					/>
				}
				nextIcon={
					<Icon
						name="arrow-thick"
						className={cx("navigationIcon", "navigationIconNext")}
					/>
				}
			/>
		);
	}
);

DatePicker.propTypes = {
	inputRef: PropTypes.shape({
		current: PropTypes.any,
	}).isRequired,
	className: PropTypes.string,
	locale: PropTypes.oneOf(["en", "fr"]),
	format: PropTypes.string,
	selectedDate: PropTypes.instanceOf(dayjs),
	minDate: PropTypes.instanceOf(dayjs),
	maxDate: PropTypes.instanceOf(dayjs),
	disabled: PropTypes.bool,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	onChange: PropTypes.func.isRequired,
	onMouseOver: PropTypes.func,
	isDefaultValue: PropTypes.bool,
	isOpenUI: PropTypes.bool,
	placeholder: PropTypes.string,
	id: PropTypes.string.isRequired,
};

DatePicker.defaultProps = {
	className: undefined,
	locale: "en",
	format: "D MMM YYYY",
	selectedDate: undefined,
	minDate: undefined,
	maxDate: undefined,
	disabled: false,
	onFocus: () => {},
	onBlur: () => {},
	onMouseOver: () => {},
	isDefaultValue: true,
	isOpenUI: true,
	placeholder: "",
};

export default DatePicker;
export { DatePicker };
