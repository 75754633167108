import React from "react";
import { Footer as FooterComponent } from "@sunwing/components";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Modal } from "../Modal";
import { useLocale, RCL, RCL as useTranslations } from "../RCL";
import { routingPaths, segmentRouteUpdate } from "../Account/config";
import styles from "./Footer.module.scss";

const Footer = () => {
	const { changeLanguage, lang } = useLocale();
	const navigate = useNavigate();

	return (
		<FooterComponent
			className={styles.customFooter}
			corps={[
				{
					id: "Terms",
					link: {
						text: useTranslations({ searchKey: "terms-and-conditions" }),
						target: "_blank",
						url: `${
							process.env.REACT_APP_SWG_DOMAIN
						}/${lang.toLowerCase()}/terms-and-conditions`,
						modalBodyText: <RCL searchKey="qba-modal-message" />,
						id: 1,
					},
				},
				{
					id: "Contact",
					link: {
						text: useTranslations({ searchKey: "contact-us" }),
						target: "_blank",
						url: `${
							process.env.REACT_APP_SWG_DOMAIN
						}/${lang.toLowerCase()}/contact-us`,
						modalBodyText: <RCL searchKey="qba-modal-message" />,
						id: 2,
					},
				},
				{
					id: "Privacy policy",
					link: {
						text: useTranslations({ searchKey: "privacy-policy" }),
						target: "_blank",
						url: `${
							process.env.REACT_APP_SWG_DOMAIN
						}/${lang.toLowerCase()}/privacy-policy`,
						modalBodyText: <RCL searchKey="qba-modal-message" />,
						id: 3,
					},
				},
			]}
			copyrightText={
				<span style={{ marginTop: 15 }}>
					{useTranslations({ searchKey: "qba-footer-copyright" })?.replace(
						"{YYYY}",
						dayjs().format("YYYY")
					)}
				</span>
			}
			renderImageLinks={link => (
				<Modal
					className={styles.footerLinks}
					modalId={link.link.id}
					buttonContent={link.link.text}
					link={link.link}
					modalContent={link.link.modalBodyText}
					modalTarget={link.link.target}
					modalUrl={link.link.url}
				/>
			)}
			lang={lang?.toLowerCase()}
			languagePages={[
				{
					id: "en",
					label: "English",
					lang: "en",
					path: "#",
				},
				{
					id: "fr",
					label: "Français",
					lang: "fr",
					path: "#",
				},
			]}
			handleChangeLanguage={page => {
				changeLanguage(page.lang);

				const currentLanguage = lang?.toLowerCase();
				const routes = Object.keys(routingPaths[lang.toLowerCase()]);

				let match = false;
				routes.forEach(route => {
					if (
						`/${currentLanguage}/${routingPaths[currentLanguage][route]}` ===
							window.location.pathname &&
						!match
					) {
						match = true;
						navigate(`/${page.lang}/${routingPaths[page.lang][route]}`);
						segmentRouteUpdate();
					}
				});

				if (!match) {
					const otherPage = window.location.pathname.replace(
						`/${currentLanguage}/`,
						`/${page.lang}/`
					);
					match = true;
					navigate(otherPage);
				}
			}}
		/>
	);
};

export default Footer;
export { Footer };
