import React, { useState, useEffect, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Row, Cell, Section } from "@sunwing/components";
import "@sunwing/components/dist/index.css";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { replaceWithJSX } from "../utils/helpers/helper";
import { Modal } from "../Modal";
import { BookingSearchBox } from "./BookingSearchBox/BookingSearchBox";
import useGatsbyAuth from "../Account/hook/useGatsbyAuth";
import { ProfileContext } from "../Account/context/ProfileProvider";
import { routingPaths } from "../Account/config";
import { RCL as useTranslation, useLocale } from "../RCL";
import { SignOutUser } from "../Account/Logout";
import styles from "./Home.module.scss";

const Home = ({ language, isValidUser }) => {
	const { lang } = useLocale();
	dayjs.locale(language);
	dayjs.extend(utc);

	const dateFormat =
		language === "fr"
			? "dddd D MMM YYYY à HH [h] mm"
			: "ddd, MMM D, YYYY h:mm A";

	const { authService, authState, loading } = useGatsbyAuth();
	const navigate = useNavigate();
	const { isVerified } = useContext(ProfileContext);

	const dictionary = {
		"qba-header-meta-title-home": useTranslation({
			searchKey: "qba-header-meta-title-home",
		}),
		"qba-header-meta-description-home": useTranslation({
			searchKey: "qba-header-meta-description-home",
		}),
		"okta-header-welcome": useTranslation({ searchKey: "okta-header-welcome" }),
		"modal-button": useTranslation({ searchKey: "already-have-a-booking-3" }),
		"qba-homepage-booking-message-1": useTranslation({
			searchKey: "qba-homepage-booking-message-1",
		}),
		"qba-homepage-message": useTranslation({
			searchKey: "qba-homepage-message",
		}),
	};

	let termsMessage = dictionary["qba-homepage-booking-message-1"];

	termsMessage = replaceWithJSX(
		termsMessage,
		"{SIGNUP_DATE}",
		dayjs(process.env.REACT_APP_SIGNUP_CUTOFF)
			.utcOffset(-4, false)
			.subtract(process.env.REACT_APP_SIGNUP_DAYS, "days")
			.format(dateFormat)
	);

	termsMessage = replaceWithJSX(
		termsMessage,
		"{SIGNUP_DATE_CUTOFF}",
		dayjs(process.env.REACT_APP_SIGNUP_CUTOFF)
			.utcOffset(-4, false)
			.format(dateFormat)
	);

	termsMessage = replaceWithJSX(
		termsMessage,
		"{DECOMISSION_DATE}",
		dayjs(process.env.REACT_APP_ACCESS_CUTOFF)
			.utcOffset(-4, false)
			.format(dateFormat)
	);

	// Session check flag
	const [isCheckSession, setCheckSession] = useState(true);
	const [isAgentUserMBP, setAgentUserMBP] = useState(false);

	// Handle visible tab session checking
	const visibilityCallback = () => {
		if (document.visibilityState === "visible") {
			setCheckSession(true);
		}
	};

	const addFocusManager = useCallback(() => {
		if (typeof window !== "undefined") {
			// Setup event for checking when user tab focus changes
			window.addEventListener("visibilitychange", visibilityCallback);
		}
	}, []);

	const checkSession = () => {
		setInterval(() => {
			setCheckSession(true);
		}, 1000 * 60 * 5);
	};

	useEffect(() => {
		if (authState && !authState.isPending) {
			addFocusManager();
			checkSession();
		}

		return () => {
			window.removeEventListener("visibilitychange", visibilityCallback);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authState, addFocusManager]);

	useEffect(() => {
		(async () => {
			if (authState && authService) {
				if (!authState.isPending && isCheckSession) {
					setCheckSession(false);

					await authService.session.exists().then(exists => {
						if (!exists || !authState.isAuthenticated) {
							// When user doesn't have a session, forget any user info
							SignOutUser(language, authService);
						} else if (
							exists &&
							authState.isAuthenticated &&
							authState.idToken.claims.agentGlobalId
						) {
							setAgentUserMBP(true);
							navigate(`/${language}/${routingPaths[language].agent}`);
						}
					});
				}
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		authState,
		authService,
		isCheckSession,
		language,
		isVerified,
		isValidUser,
	]);

	if (isAgentUserMBP || loading) {
		return null;
	}
	return (
		<>
			<Helmet>
				<meta
					name="description"
					content={dictionary["qba-header-meta-description-home"]}
				/>
				<meta
					property="og:title"
					content={dictionary["qba-header-meta-title-home"]}
				/>
				<meta
					property="og:description"
					content={dictionary["qba-header-meta-description-home"]}
				/>
				<title>{dictionary["qba-header-meta-title-home"]}</title>
			</Helmet>
			<Section
				title={dictionary["okta-header-welcome"]}
				titleAs="h1"
				titleSize="h1"
				titleUppercase={true}
				titleWeight="bold"
				titleAlignment="left"
				className={styles.homeContent}
			>
				{dictionary["qba-homepage-message"]}
			</Section>
			{!loading && authState?.isAuthenticated && isVerified && (
				<Section padding="top">
					<div className="homeBookingContainer">
						<BookingSearchBox
							tabs="packages,flights"
							mode="tabs"
							key={lang}
							lang={lang.toLowerCase()}
						/>
					</div>
				</Section>
			)}
			<Section padding="top" className={styles.homeContent}>
				<Row cols={1}>
					<Cell>
						{replaceWithJSX(
							termsMessage,
							"{0}",
							<Modal
								modalTarget="_blank"
								buttonContent={dictionary["modal-button"]}
								modalUrl={`${process.env.REACT_APP_SWG_DOMAIN}/user-account/login?loadSession=true`}
							/>
						)}
					</Cell>
				</Row>
			</Section>
		</>
	);
};

Home.propTypes = {
	language: PropTypes.oneOf(["en", "fr"]).isRequired,
	isValidUser: PropTypes.bool,
};

Home.defaultProps = {
	isValidUser: undefined,
};

export default Home;
export { Home };
