import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Section } from "@sunwing/components";
import { Modal } from "../Modal";
import "@sunwing/components/dist/index.css";
import useGatsbyAuth from "../Account/hook/useGatsbyAuth";
import { RCL, RCL as useTranslation } from "../RCL";
import { routingPaths } from "../Account/config";
import { SignOutUser } from "../Account/Logout";
import styles from "./Agent.module.scss";

const Agent = ({ language }) => {
	const navigate = useNavigate();
	const { authState, authService, loading } = useGatsbyAuth();
	const dictionary = {
		"qba-header-meta-title-agent": useTranslation({
			searchKey: "qba-header-meta-title-agent",
		}),
		"qba-header-meta-description-agent": useTranslation({
			searchKey: "qba-header-meta-description-agent",
		}),
		"okta-header-welcome": useTranslation({ searchKey: "okta-header-welcome" }),
		"qba-okta-agent-direct-login-action": useTranslation({
			searchKey: "qba-okta-agent-direct-login-action",
		}),
		"qba-agent-modal-message": useTranslation({
			searchKey: "qba-agent-modal-message",
		}),
	};

	// Session check flag
	const [isCheckSession, setCheckSession] = useState(true);

	// Handle visible tab session checking
	const visibilityCallback = () => {
		if (document.visibilityState === "visible") {
			setCheckSession(true);
		}
	};

	const addFocusManager = useCallback(() => {
		if (typeof window !== "undefined") {
			// Setup event for checking when user tab focus changes
			window.addEventListener("visibilitychange", visibilityCallback);
		}
	}, []);

	const checkSession = () => {
		setInterval(() => {
			setCheckSession(true);
		}, 1000 * 60 * 5);
	};

	useEffect(() => {
		if (authState && !authState.isPending) {
			addFocusManager();
			checkSession();
		}

		return () => {
			window.removeEventListener("visibilitychange", visibilityCallback);
		};
	}, [authState, addFocusManager]);

	useEffect(() => {
		(async () => {
			if (authState && authService) {
				if (!authState.isPending && isCheckSession) {
					setCheckSession(false);

					await authService.session.exists().then(exists => {
						if (!exists || !authState.isAuthenticated) {
							// When user doesn't have a session, forget any user info
							SignOutUser(language, authService);
						} else if (exists && !authState?.idToken?.claims?.agentGlobalId) {
							navigate(`/${language}/${routingPaths[language].home}`);
						}
					});
				}
			}
		})();
	}, [authState, authService, isCheckSession, language, navigate]);

	if (loading) {
		return null;
	}
	return (
		<>
			<Helmet>
				<meta
					name="description"
					content={dictionary["qba-header-meta-description-agent"]}
				/>
				<meta
					property="og:title"
					content={dictionary["qba-header-meta-title-agent"]}
				/>
				<meta
					property="og:description"
					content={dictionary["qba-header-meta-description-agent"]}
				/>
				<title>{dictionary["qba-header-meta-title-agent"]}</title>
			</Helmet>
			<Section
				title={dictionary["okta-header-welcome"]}
				titleAs="h1"
				titleSize="h1"
				titleUppercase={true}
				titleWeight="bold"
				titleAlignment="left"
			>
				<div className={styles.agentContent}>
					<RCL searchKey="qba-okta-agent-direct-login" />{" "}
					<Modal
						modalContent={<RCL searchKey="qba-agent-modal-message" />}
						buttonContent={`${dictionary["qba-okta-agent-direct-login-action"]}.`}
						modalUrl={`${process.env.REACT_APP_AGENT_DOMAIN}/${language}/`}
					/>
				</div>
			</Section>
		</>
	);
};

Agent.propTypes = {
	language: PropTypes.oneOf(["en", "fr"]).isRequired,
};

export default Agent;
export { Agent };
