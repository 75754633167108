import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Button, Modal as ModalComponent } from "@sunwing/components";
import { RCL, RCL as useTranslation } from "../RCL";
import styles from "./Modal.module.scss";

const Modal = ({
	className,
	buttonContent,
	modalContent,
	modalUrl,
	modalTarget,
	modalId,
	link,
	isOpenDefault,
	modalType,
	onClose,
	onOpen,
}) => {
	const [id, setId] = useState(null);
	const [isOpen, setIsOpen] = useState(isOpenDefault);

	const labels = {
		close: useTranslation({ searchKey: "modal-close" }),
		content: useTranslation({ searchKey: "modal-content-label" }),
		cancel: useTranslation({ searchKey: "okta-profile-panel-cancel" }),
	};

	if (modalType === "custom") {
		return (
			<ModalComponent
				labels={{ content: labels.content, close: labels.close }}
				container="md"
				top={true}
				isOpen={isOpen}
				onClose={() => {
					setIsOpen(false);
					onClose?.();
				}}
				overlayClassName={styles.modalOverlay}
			>
				<ModalComponent.Content className={cx(styles.modalInner, className)}>
					{modalContent}
				</ModalComponent.Content>
			</ModalComponent>
		);
	}
	return (
		<>
			{buttonContent && (
				<Button
					type="button"
					onClick={() => {
						setIsOpen(!isOpen);
						setId(id);
						onOpen?.();
					}}
					className={cx(className, styles.link)}
					noStyle={true}
				>
					{buttonContent}
				</Button>
			)}
			{!modalId && (
				<ModalComponent
					labels={{ content: labels.content, close: labels.close }}
					container="sm"
					top={true}
					isOpen={isOpen}
					onClose={() => {
						setIsOpen(false);
					}}
					overlayClassName={styles.modalOverlay}
				>
					<ModalComponent.Content className={styles.modalInner}>
						<p>{modalContent}</p>
						{modalUrl && (
							<Button
								type="button"
								className={styles.modalBtn}
								size="md"
								target={modalTarget}
								to={modalUrl}
							>
								<RCL searchKey="okta-profile-feedback-proceed" />
							</Button>
						)}
						<Button
							type="button"
							className={styles.modalBtn}
							outline={true}
							size="md"
							onClick={() => setIsOpen(false)}
						>
							{labels.cancel}
						</Button>
					</ModalComponent.Content>
				</ModalComponent>
			)}
			{modalId && Object.values(link).indexOf(modalId) > -1 ? (
				<ModalComponent
					labels={{ content: labels.content, close: labels.close }}
					container="sm"
					top={true}
					isOpen={isOpen}
					onClose={() => {
						setIsOpen(false);
					}}
					overlayClassName={styles.modalOverlay}
				>
					<ModalComponent.Content className={styles.modalInner}>
						<p>{modalContent}</p>
						{modalUrl && (
							<Button
								type="button"
								className={styles.modalBtn}
								size="md"
								target={modalTarget}
								to={modalUrl}
							>
								<RCL searchKey="okta-profile-feedback-proceed" />
							</Button>
						)}
						<Button
							type="button"
							className={styles.modalBtn}
							outline={true}
							size="md"
							onClick={() => setIsOpen(false)}
						>
							{labels.cancel}
						</Button>
					</ModalComponent.Content>
				</ModalComponent>
			) : (
				""
			)}
		</>
	);
};

Modal.propTypes = {
	className: PropTypes.string,
	buttonContent: PropTypes.string,
	modalContent: PropTypes.object,
	modalUrl: PropTypes.string,
	modalTarget: PropTypes.string,

	// specific to footer links/modals
	modalId: PropTypes.number,
	link: PropTypes.object,
	isOpenDefault: PropTypes.bool,
	modalType: PropTypes.oneOf(["custom", "default"]),
	onClose: PropTypes.func,
	onOpen: PropTypes.func,
};

Modal.defaultProps = {
	className: undefined,
	buttonContent: undefined,
	modalContent: <RCL searchKey="qba-modal-message" />,
	modalUrl: undefined,
	modalTarget: "_self",
	modalId: null,
	link: undefined,
	isOpenDefault: false,
	modalType: "default",
	onClose: () => {},
	onOpen: () => {},
};

export default Modal;
export { Modal };
