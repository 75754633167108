import { useContext } from "react";
import parse from "html-react-parser";
import { marked } from "marked";
import PropTypes from "prop-types";
import { RCLContext } from "./RCLProvider";

const RCL = ({ searchKey }) => {
	const {
		state: { lang, dictionary },
	} = useContext(RCLContext);

	try {
		const item = dictionary[lang][searchKey];

		if (item.parseAsMarkdown) {
			return parse(marked.parse(item.value));
		}
		return item.value;
	} catch {
		if (process.env.NODE_ENV === "development") {
			console.warn(`Cannot find key '${searchKey}' in the dictionary`);
		}
		return null;
	}
};

RCL.propTypes = {
	searchKey: PropTypes.string.isRequired,
};

export default RCL;
