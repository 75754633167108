/* eslint-disable no-param-reassign */
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import OktaSignIn from "@okta/okta-signin-widget";
import { config, socialLogins } from "../../config";
import { getURLQueries } from "../../../utils/helpers/helper";
import "./themeIE.scss";

let isSocialDisabled = false;

const OktaSignInWidgetIE = ({
	lang,
	isAccountCreated,
	isAccountVerified,
	setIsAccountVerified,
	isActionDefault,
	dictionary,
	isShowSignup,
	className,
	email,
	setWidgetFlow,
	isSocialNotify,
	setDisplaySocialModal,
	setSocialDetails,
	selectedIdp,
	verifyStatus,
	setVerifyStatus,
}) => {
	isSocialDisabled = isSocialNotify;
	// Injection states
	let isAccountCreatedInjection = false;
	const widgetRef = useRef(null);
	let isAction = isActionDefault || getURLQueries("action");
	const loginHint = getURLQueries("login_hint");
	const state = getURLQueries("state");
	const otp = getURLQueries("otp");

	const widgetInstance = useRef();

	const cleanQueryURL = _queries => {
		const siteUrl = new URL(window.location.href);
		const queries = siteUrl.searchParams;

		_queries.forEach(query => {
			queries.set(query, "");
		});

		siteUrl.search = queries.toString();

		const obj = { Title: document.title, Url: siteUrl.toString() };
		window.history.pushState(obj, obj.Title, obj.Url);
	};

	const processHelpLinks = linksCopy => {
		if (typeof linksCopy !== "string") {
			return null;
		}
		const textLinks = linksCopy.trim().replace(/\n/g, "").split(",");
		const linksArray = [];

		textLinks.forEach(textLink => {
			const textArray = textLink.trim().split(";");

			const linkObject = {
				text: "",
				href: "",
				target: null,
			};

			let linkFlag = false;

			for (let i = 0; i < textArray.length; i++) {
				// Sanitize array
				const tempString = textArray[i].trim();

				if (tempString.length) {
					textArray[i] = tempString;
				} else {
					textArray[i] = null;
				}

				// Build output array
				if (textArray[i]) {
					// eslint-disable-next-line no-loop-func
					Object.keys(linkObject).forEach(key => {
						const textItem = textArray[i].split(":");

						if (key === textItem[0]) {
							switch (key) {
								case "text":
									// eslint-disable-next-line prefer-destructuring
									linkObject[key] = textItem[1];
									linkFlag = true;
									break;
								case "href":
									// eslint-disable-next-line no-case-declarations
									const href = textArray[i].substr(
										key.length + 1,
										textArray[i].length - 1
									);
									linkObject[key] = href;
									linkFlag = true;
									break;
								case "target":
									// eslint-disable-next-line prefer-destructuring
									linkObject[key] = textItem[1];
									linkFlag = true;
									break;
								default:
									linkFlag = false;
							}
						}
					});
				}
			}

			// Update output array
			if (linkFlag && linkObject.text && linkObject.href) {
				linksArray.push(linkObject);
			}
		});

		if (linksArray) {
			return linksArray;
		}
		return null;
	};

	const customHelpLinks = processHelpLinks(
		dictionary["okta-helplinks-custom-qba"]
	);

	// Build IDP array
	const socialLoginArray = socialLogins.map(idp => ({
		...idp,
		text: " ",
	}));

	const injectLegal = (_ref, _add = false) => {
		// Form element target for legal
		const contentDIV = _ref.getElementsByClassName("auth-content")?.[0];

		// Check if legal copy exists in widget
		const legalElementDIV = _ref.querySelector(".legal");
		if (!legalElementDIV && _add) {
			// Inject legal copy into widget
			const legalDIV = document.createElement("div");
			legalDIV.innerHTML = dictionary["okta-widget-legal"];
			legalDIV.className = "legal";

			contentDIV.appendChild(legalDIV);
		} else if (!_add) {
			// Remove all legal
			const legalDIV = _ref.querySelectorAll(".legal");

			if (legalDIV.length > 0) {
				legalDIV.forEach(item => {
					item.parentNode.removeChild(item);
				});
			}
		}
	};

	const injectAccountCreated = (_ref, _injectState, _displayIcon) => {
		const oktaHeader = widgetRef.current.getElementsByClassName(
			"okta-sign-in-header"
		)?.[0];

		if (
			(isAccountCreated &&
				!isAccountCreatedInjection &&
				_injectState === "add") ||
			(isAccountVerified && verifyStatus && _injectState === "add")
		) {
			if (oktaHeader) {
				// Inject account creation status into widget
				const activateElement = document.createElement("div");
				activateElement.className = `account-activation ${
					isAccountVerified ? "verify" : ""
				}`;

				const headingElement = document.createElement("span");
				headingElement.className = "heading";

				const headingIcon = document.createElement("span");
				headingIcon.className = isAccountVerified
					? "swg-icon swg-icon-checked"
					: "swg-icon swg-icon-checked--o";
				headingElement.appendChild(headingIcon);

				const headingText = document.createElement("span");
				headingText.className = "status";
				headingText.innerText = isAccountVerified
					? dictionary["verify-successful-class-member-status"]
					: dictionary["okta-widget-new-account-heading"];
				headingElement.appendChild(headingText);

				activateElement.appendChild(headingElement);

				const messageElement = document.createElement("p");
				messageElement.className = "message";

				let message = "";

				if (verifyStatus === "verified") {
					message = "verify-status-verified-message-200";
				} else if (verifyStatus === "account-created") {
					message = "verify-status-verified-message-201";
				}

				messageElement.innerText = isAccountVerified
					? dictionary[message]
					: dictionary["okta-widget-new-account-message"];
				activateElement.appendChild(messageElement);

				oktaHeader.appendChild(activateElement);

				if (isAccountCreated || isAccountVerified) {
					oktaHeader.classList.add("activate");
					oktaHeader.classList.remove("icon");
				}

				// Modify registration query strings so if the user bookmarks, the success message will not display again
				cleanQueryURL(["type_hint", "session_hint"]);
				isAccountCreatedInjection = true;
				setIsAccountVerified(false);
			}
		} else {
			const activateElement =
				widgetRef.current.getElementsByClassName("account-activation")?.[0];

			if (activateElement && isAccountCreatedInjection) {
				activateElement.parentNode.removeChild(activateElement);
				oktaHeader.classList.remove("activate");

				isAccountCreatedInjection = false;
			}

			if (_displayIcon) {
				oktaHeader.classList.add("icon");
			} else {
				oktaHeader.classList.remove("icon");
			}
		}
	};

	const interceptModal = (e, idp) => {
		if (isSocialDisabled) {
			e.preventDefault();
			e.stopPropagation();

			setSocialDetails({
				idp,
			});
			setDisplaySocialModal(true);
		}
	};

	const updateSocialButtons = (instance, id) => {
		// Social media buttons
		const socialMediaButtons = instance.querySelectorAll(
			".social-auth-button.link-button"
		);

		if (socialMediaButtons) {
			// Remove text and add accessibility label
			let buttonClasses = "";
			let idp = "";

			socialMediaButtons.forEach(button => {
				let isFound = false;
				buttonClasses = button.className;

				socialLoginArray.forEach(idpButton => {
					idp = idpButton.type.toLowerCase();

					if (!isFound && buttonClasses) {
						if (buttonClasses?.indexOf(idp) > -1) {
							button.ariaLabel = dictionary[`okta-widget-sign-in-with-${idp}`];
							button.innerText = "";
							isFound = true;

							if (isSocialDisabled && idpButton.notify) {
								button.addEventListener(
									"click",
									e => interceptModal(e, idpButton),
									true
								);
							} else if (id === idpButton.id) {
								button.click();
							}
						} else {
							button.ariaLabel = "";
						}
					}
				});
			});
		}
	};

	const handleDefaultView = _ref => {
		const unlockElement = _ref.getElementsByClassName("js-unlock")?.[0];

		// Detection for account actions and defaults to screen based on querystring
		if (isAction === "unlock") {
			unlockElement.click();

			// Modify action query string so if user cancels flow it will not default again
			cleanQueryURL(["action"]);
			isAction = "";
		}
	};

	const handleLoginEmail = _ref => {
		// Detection for account actions and defaults to screen based on querystring
		if (loginHint || email) {
			const updateHint = (usernameElement, userEmail) => {
				if (usernameElement && userEmail) {
					usernameElement.value = decodeURIComponent(userEmail);
					usernameElement.dispatchEvent(new Event("change", { bubbles: true }));
				}
			};

			// Auto fill the email when new account creation is sucessful
			const usernameSignIn = _ref.querySelector("[name='identifier']");

			if (usernameSignIn) {
				updateHint(usernameSignIn, loginHint || email);

				const nextButton = _ref.querySelector(".button-primary");

				if (nextButton) {
					setTimeout(() => {
						nextButton.focus();
					}, 200);
				}
			}
		}
	};

	const moveRegistration = _ref => {
		// Move Sign-up container
		const authContainer = _ref.getElementsByClassName("sign-in-with-idp")?.[0];
		const signUpContainer = _ref.getElementsByClassName("footer-info")?.[0];
		const signUpElement = _ref.querySelector("[data-se='enroll']");

		if (signUpContainer && isShowSignup) {
			// Attach cleanup

			signUpElement.addEventListener("click", e => {
				e.preventDefault();
				setIsAccountVerified(false);
				setVerifyStatus(false);
				setWidgetFlow("signup");
			});

			// Relocate signup
			signUpContainer.classList.add("credential-su");
			authContainer.insertAdjacentElement("beforebegin", signUpContainer);
		} else {
			signUpContainer?.parentNode.removeChild(signUpContainer);
		}
	};

	const removeHelpLink = _ref => {
		// Remove help link
		const helpElement = _ref.querySelector(".js-help");
		if (helpElement) {
			helpElement.parentNode.removeChild(helpElement);
		}
	};

	const manageWidget = (_widgetConfig, _ref, _action) => {
		switch (_action) {
			case "create":
				localStorage.removeItem("okta-cache-storage");

				// Init instance
				widgetInstance.current = new OktaSignIn(_widgetConfig);
				if (_ref) {
					widgetInstance.current.showSignInAndRedirect({ el: _ref });

					widgetInstance.current.on("afterError", (_context, _error) => {
						if (
							_error?.xhr?.responseJSON?.errorSummaryKeys.includes(
								"idx.session.expired"
							)
						) {
							console.error(
								"afterError: reinitialize widget with timeout error"
							);

							manageWidget(_widgetConfig, _ref, "destroy");

							setTimeout(() => {
								manageWidget(_widgetConfig, _ref, "create");
							}, 500);
						}
					});

					widgetInstance.current.on("afterRender", _context => {
						if (
							_context.formName === "terminal" ||
							_context.formName === "reset-authenticator"
						) {
							injectAccountCreated(widgetRef.current, "remove", true);
						}
					});
				}

				break;
			case "destroy":
				if (widgetInstance.current) {
					widgetInstance.current.remove();
					sessionStorage.removeItem("okta-transaction-storage");
				}
				break;

			default:
				break;
		}
	};

	const widgetConfig = {
		...config,
		useClassicEngine: false,
		useInteractionCodeFlow: true,
		baseUrl: config.domain,
		redirectUri: `${window.location.origin}/${lang}/login/callback`,
		authParams: {
			issuer: config.issuer,
			scopes: config.scopes,
			pkce: config.pkce,
		},
		language: lang,
		features: {
			registration: true,
			rememberMe: true,
			selfServiceUnlock: true,
		},
		state,
		otp,
		hooks: {
			identify: {
				after: [
					async function afterSigninRedirect() {
						// Default screen for login flow
						setTimeout(() => {
							moveRegistration(widgetRef.current);
							removeHelpLink(widgetRef.current);
							injectAccountCreated(widgetRef.current, "add");
							injectLegal(widgetRef.current);

							handleDefaultView(widgetRef.current);
							handleLoginEmail(widgetRef.current);

							updateSocialButtons(widgetRef.current);
						}, 1);
					},
				],
			},
			"identify-recovery": {
				after: [
					async function afterForgotRedirect() {
						// Forgot password screen

						injectAccountCreated(widgetRef.current, "remove");
					},
				],
			},
			"select-authenticator-enroll": {
				after: [
					async function afterAuthenticationSelectEnrollRedirect() {
						// Enrollment security options

						injectAccountCreated(widgetRef.current, "remove");
					},
				],
			},
			"select-authenticator-unlock-account": {
				after: [
					async function afterUnlockRedirect() {
						// Unlock account entry screen

						injectAccountCreated(widgetRef.current, "remove");
					},
				],
			},
			"select-authenticator-authenticate": {
				after: [
					async function afterAuthenticateSelectRedirect() {
						// Account authentication screen

						injectAccountCreated(widgetRef.current, "remove");
					},
				],
			},
			"authenticator-verification-data": {
				after: [
					async function afterVerifyEmailRedirect() {
						// Account verify email screen

						injectAccountCreated(widgetRef.current, "remove", true);
					},
				],
			},
			"challenge-authenticator": {
				after: [
					async function afterChallengeRedirect() {
						// Verification challenge screen

						injectAccountCreated(widgetRef.current, "remove", true);
					},
				],
			},
			"enroll-authenticator": {
				after: [
					async function afterEnrollAuthenticateRedirect() {
						// Enrollment verification request screen

						injectAccountCreated(widgetRef.current, "remove", true);
					},
				],
			},
		},
		helpLinks: {
			custom: customHelpLinks,
		},
		i18n: {
			[lang]: {
				// COMMON LABELS
				"primaryauth.username.placeholder":
					dictionary["okta-widget-email-placeholder"],
				unlockaccount: dictionary["okta-widget-unlock-account"],
				"socialauth.divider.text": dictionary["okta-widget-social-text-v2"],
				goback: dictionary["okta-widget-goback-text"],
				help: " ",

				// ERRORS
				"errors.E0000004": dictionary["okta-widget-error-E0000004"],
				"errors.E0000006": dictionary["okta-widget-error-E0000006"],
				"errors.E0000119": dictionary["okta-widget-error-E0000119"],

				"registration.signup.label": dictionary["okta-widget-sign-up-label"],

				// OIE
				"oie.registration.form.title": dictionary["okta-widget-sign-up-text"],
				"oie.registration.form.submit":
					dictionary["okta-widget-create-an-account"],
				haveaccount: dictionary["okta-widget-goback-text"],
			},
		},
		idps: socialLoginArray,
		registration: {
			click: () => {
				// Trigger verification flow
				setWidgetFlow("signup");
			},
		},
	};

	useEffect(() => {
		if (!widgetRef.current) return false;

		manageWidget(widgetConfig, widgetRef.current, "create");

		return () => {
			if (widgetInstance.current) {
				widgetInstance.current.remove();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isSocialNotify === false) {
			updateSocialButtons(widgetRef.current, selectedIdp);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSocialNotify, selectedIdp]);

	return <div className={`sso-widget ${className}`} ref={widgetRef} />;
};

OktaSignInWidgetIE.propTypes = {
	lang: PropTypes.oneOf(["en", "fr"]),
	isAccountCreated: PropTypes.bool.isRequired,
	isAccountVerified: PropTypes.bool.isRequired,
	setIsAccountVerified: PropTypes.func.isRequired,
	isActionDefault: PropTypes.string,
	dictionary: PropTypes.object.isRequired,
	isShowSignup: PropTypes.bool.isRequired,
	className: PropTypes.string.isRequired,
	setWidgetFlow: PropTypes.func.isRequired,
	email: PropTypes.string,
	isSocialNotify: PropTypes.bool.isRequired,
	setSocialDetails: PropTypes.func.isRequired,
	setDisplaySocialModal: PropTypes.func.isRequired,
	selectedIdp: PropTypes.string,
	verifyStatus: PropTypes.string,
	setVerifyStatus: PropTypes.func.isRequired,
};

OktaSignInWidgetIE.defaultProps = {
	lang: "en",
	isActionDefault: undefined,
	email: undefined,
	selectedIdp: undefined,
	verifyStatus: undefined,
};

export default OktaSignInWidgetIE;
