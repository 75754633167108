/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-danger */
import React, { useState, useContext, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { Row, Cell, Section, Button, Heading } from "@sunwing/components";
import "@sunwing/components/dist/index.css";
import dayjs from "dayjs";
import { RCL } from "../RCL";
import { SEGMENT_CONTEXT } from "../Account/config";
import { ProfileContext } from "../Account/context/ProfileProvider";
import { cleanString, updateContext } from "../utils/helpers/helper";
import { DatePicker, InputWrapper } from "../Common";

import styles from "./Verify.module.scss";

const cx = classNames.bind(styles);

const Verify = ({
	language,
	dictionary,
	setWidgetFlow,
	setModalOpen,
	setVerifyStatus,
}) => {
	const [submitting, setSubmitting] = useState(false);
	const inputBirthDateRef = useRef(null);
	const [birthDate, setBirthDate] = useState(undefined);
	const [isHovered, setIsHovered] = useState(false);
	const { updateProfile } = useContext(ProfileContext);
	const programId = window.btoa(process.env.REACT_APP_PROGRAM_ID);

	// Validation tracking and true data source
	const [verifyConfig, setConfigState] = useState({
		fields: {
			firstName: {
				value: "",
				isRequired: true,
				isValid: false,
				hasChanged: false,
				validationType: "name",
			},
			lastName: {
				value: "",
				isRequired: true,
				isValid: false,
				hasChanged: false,
				validationType: "name",
			},
			email: {
				value: "",
				isRequired: true,
				isValid: false,
				hasChanged: false,
				validationType: "email",
			},
			birthDate: {
				value: "",
				isRequired: true,
				isValid: false,
				hasChanged: false,
				validationType: "date",
			},
			subscribe: {
				value: "",
				isRequired: false,
				isValid: true,
				hasChanged: false,
				validationType: "checkbox",
			},
		},
		isValid: false,
		hasChanged: false,
	});

	const validateField = (fieldName, fieldValue, type, required) => {
		const fieldValueTrimmed = fieldValue?.trim() || "";

		// Regex tests to validate each field type
		if (type === "name") {
			const characterLimit = 50;

			if (fieldValueTrimmed !== cleanString(fieldValueTrimmed)) {
				return "special-characters-found";
			}

			if (required) {
				if (fieldValueTrimmed.length < 1) {
					return "required";
				}

				if (fieldValueTrimmed.length > characterLimit) {
					return false;
				}

				return true;
			}
		} else if (type === "date") {
			if (required) {
				if (fieldValueTrimmed.length < 1) {
					return "required";
				}
				return /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(fieldValue);
			}

			if (!required) {
				if (fieldValueTrimmed.length < 1) {
					return true;
				}
				return /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(fieldValue);
			}
		} else if (type === "email") {
			const emailRegex =
				/^(([^<>()[\]\\.,;:\s@'“‘”’]+(\.[^<>()[\]\\.,;:\s@'“‘”’]+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

			if (required === "required") {
				if (fieldValueTrimmed.length < 1) {
					return "required";
				}
				return emailRegex.test(fieldValue);
			}
			if (fieldValueTrimmed.length < 1) {
				return "required";
			}

			return emailRegex.test(fieldValue);
		} else if (type === "checkbox") {
			if (required) {
				if (
					document.getElementsByName(fieldName)[0] &&
					document.getElementsByName(fieldName)[0].checked
				) {
					return true;
				}
				return "required";
			}

			return true;
		}

		return false;
	};

	const updateConfigFieldData = (formField, fieldFieldValue, eventType) => {
		/* Purpose: Individual fields are analyzed and updated in verifyConfig */
		const tempConfig = { ...verifyConfig };

		if (formField && typeof fieldFieldValue !== "undefined") {
			const field = tempConfig.fields[formField];

			field.hasChanged = fieldFieldValue.trim() !== undefined;
			field.value =
				eventType === "blur" ? fieldFieldValue.trim() : fieldFieldValue;

			field.isValid = validateField(
				formField,
				fieldFieldValue,
				verifyConfig.fields[formField].validationType,
				verifyConfig.fields[formField].isRequired
			);
		}

		// Check all fields are valid
		const fieldKeyList = Object.keys(tempConfig.fields);
		let validRequiredTotal = 0;
		let validRequiredCounter = 0;
		let changedFlag = false;

		fieldKeyList.forEach(key => {
			const field = tempConfig.fields[key];

			if (field.isRequired || (!field.isRequired && field.hasChanged)) {
				validRequiredTotal += 1;
			}

			if (field.isValid === true) {
				if (field.isRequired || (!field.isRequired && field.hasChanged)) {
					validRequiredCounter += 1;
				}
			}
			if (field.hasChanged) {
				changedFlag = true;
			}
		});

		tempConfig.hasChanged = changedFlag;

		if (validRequiredCounter === validRequiredTotal) {
			tempConfig.isValid = true;
		} else {
			tempConfig.isValid = false;
		}

		setConfigState(tempConfig);
	};

	const handleChange = event => {
		/* Purpose: Updates the profile temp state per field */
		const { name: field, value } = event.target;

		updateConfigFieldData(field, value, event?.type);
	};

	const handleDateChange = value => {
		updateConfigFieldData("birthDate", value);
	};

	const registerUser = async payload =>
		fetch(
			`${process.env.REACT_APP_SWG_APIM_API}/auth/${language}?type=wopass`,
			{
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(payload),
				method: "POST",
			}
		)
			.then(async res => {
				const { status } = res;

				if (!res.ok) {
					if (status !== 200) {
						return res.json().then(json => {
							if (status === 400 && json?.errorCode) {
								// Catch Auth API error object format
								// eslint-disable-next-line no-throw-literal
								throw { ...json, type: "custom", status };
							} else {
								// eslint-disable-next-line no-throw-literal
								throw { ...json, type: "standard", status };
							}
						});
					}
				}
				return res.json().then(json => ({ ...json, type: "custom", status }));
			})
			.catch(err => {
				console.error("registerUser:: error: ", err);
				return { ...err };
			});

	const submitVerify = async () => {
		setSubmitting(true);

		// Display loader
		setModalOpen(true);
		setVerifyStatus("loading");

		window.analytics.track(
			"Sign Up Submitted",
			{ email: verifyConfig.fields.email.value },
			SEGMENT_CONTEXT
		);

		const payload = {
			appID: process.env.REACT_APP_CREATED_SOURCE,
			userID: verifyConfig.fields.email.value,
			userPassword: null,
			userProfile: {
				firstName: verifyConfig.fields.firstName.value,
				lastName: verifyConfig.fields.lastName.value,
				email: verifyConfig.fields.email.value,
				contactType: "Customer",
				additionalProperties: [
					{
						code: "affiliateCode",
						value: window.btoa(process.env.REACT_APP_PROGRAM_ID),
					},
					{
						code: "birthDate",
						value: verifyConfig.fields.birthDate.value,
					},
					{
						code: "IsSubscribed",
						value: verifyConfig.fields.subscribe.value,
					},
				],
			},
		};

		await registerUser(payload).then(response => {
			updateProfile({
				firstName: verifyConfig.fields.firstName.value,
				lastName: verifyConfig.fields.lastName.value,
				email:
					verifyConfig.fields.email.value.indexOf("privaterelay.appleid.com") <=
					-1
						? verifyConfig.fields.email.value
						: "",
				birthDate: verifyConfig.fields.birthDate.value,
			});

			let status;

			if (response?.type === "custom") {
				switch (response.status) {
					case 200:
						status = "verified";
						break;

					case 201:
						status = "account-created";
						updateContext(verifyConfig.fields.email.value, programId);
						break;

					case 400:
						switch (response?.errorCode) {
							case "EAU0002":
								status = "invalid-user-EAU0002";
								// Invalid | Ineligible User
								break;

							case "EAU0001":
								status = "invalid-user-EAU0001";
								// User already exists
								break;

							case "EXX0002":
								status = "invalid-user-EXX0002";
								// Invalid Arguments: Email Id is not in correct format.
								break;

							default:
								status = "invalid-user-EXX0000";
								// Generic Error | App Id missing | missing user id.
								break;
						}

						break;
					default:
						status = "error";

						break;
				}

				window.analytics.track(
					response.status === 200 || response.status === 201
						? "Sign Up Verified"
						: "Sign Up Rejected",
					{ email: verifyConfig.fields.email.value, status },
					SEGMENT_CONTEXT
				);
			} else {
				status = "error";
			}

			setVerifyStatus(status);
			setSubmitting(false);
		});
	};

	const displayValidationMessage = (fieldName, type) => {
		const isValid = validateField(
			fieldName,
			verifyConfig.fields[fieldName].value,
			type,
			verifyConfig.fields[fieldName].isRequired
		);

		let errorMessage = null;

		switch (isValid) {
			case "required":
				errorMessage = (
					<div className={cx("validationErrorMessage")}>
						<RCL searchKey="okta-profile-validation-this-field-is-required" />
					</div>
				);
				break;
			case "special-characters-found":
				errorMessage = (
					<div className={cx("validationErrorMessage")}>
						<RCL searchKey="okta-profile-validation-invalid-special-chars" />
					</div>
				);
				break;
			case false:
				errorMessage = (
					<div className={cx("validationErrorMessage")}>
						<RCL searchKey="oktta-profile-validation-this-field-is-invalid" />
					</div>
				);
				break;

			default:
				errorMessage = null;
		}

		return errorMessage;
	};

	return (
		<Section className={styles.verify}>
			<Row cols={1}>
				<Cell>
					<Heading as="h1" size="h2" className={styles.verifyHeading}>
						{dictionary["okta-widget-verify-heading"]}
					</Heading>
				</Cell>
				<Cell>
					<div className={styles.inputContainer}>
						<label htmlFor="ap-firstName">
							<span className={cx(styles.inputContainerLabel, styles.required)}>
								<RCL searchKey="okta-profile-panel-first-name" />
							</span>
							<input
								value={verifyConfig.fields.firstName.value}
								type="text"
								id="ap-firstName"
								name="firstName"
								maxLength="50"
								onChange={handleChange}
								onBlur={handleChange}
								disabled={submitting}
								placeholder={`${
									dictionary?.["okta-profile-panel-first-name"] || ""
								} *`}
							/>
						</label>
						{verifyConfig.fields.firstName.hasChanged &&
							displayValidationMessage("firstName", "name")}
					</div>
				</Cell>
				<Cell>
					<div className={styles.inputContainer}>
						<label htmlFor="ap-lastName">
							<span className={cx(styles.inputContainerLabel, styles.required)}>
								<RCL searchKey="okta-profile-panel-last-name" />
							</span>
							<input
								value={verifyConfig.fields.lastName.value}
								type="text"
								id="ap-lastName"
								name="lastName"
								maxLength="50"
								onChange={handleChange}
								onBlur={handleChange}
								disabled={submitting}
								placeholder={`${
									dictionary?.["okta-profile-panel-last-name"] || ""
								} *`}
							/>
						</label>
						{verifyConfig.fields.lastName.hasChanged &&
							displayValidationMessage("lastName", "name")}
					</div>
				</Cell>
				<Cell>
					<div className={cx(styles.inputContainer, styles.datePicker)}>
						<span className={cx("controlLabel")}>
							<InputWrapper
								className={styles.inputDatePicker}
								ref={inputBirthDateRef}
								id="ap-birthDate"
								label={dictionary["okta-profile-my-trips-dob"]}
							>
								{() => (
									<DatePicker
										className={isHovered && !submitting ? styles.hovered : ""}
										id="ap-birthDate-input"
										locale={language}
										inputRef={inputBirthDateRef}
										selectedDate={birthDate}
										minDate={dayjs().startOf("day").subtract(100, "year")}
										maxDate={dayjs().startOf("day")}
										onChange={value => {
											setBirthDate(value);
											handleDateChange(value.format("YYYY-MM-DD"));
										}}
										isDefaultValue={false}
										isOpenUI={false}
										disabled={submitting}
										placeholder={`${
											dictionary?.["okta-profile-my-trips-dob"] || ""
										} *`}
										onMouseOver={value => setIsHovered(value)}
									/>
								)}
							</InputWrapper>
						</span>
						<span className={styles.note}>
							<RCL searchKey="okta-profile-panel-dob-note" />
						</span>
						{verifyConfig.fields.birthDate.hasChanged &&
							displayValidationMessage("birthDate", "date")}
					</div>
				</Cell>
				<Cell>
					<div className={styles.inputContainer}>
						<label htmlFor="ap-email">
							<span className={cx(styles.inputContainerLabel, styles.required)}>
								<RCL searchKey="okta-profile-panel-email" />
							</span>
							<input
								value={verifyConfig.fields.email.value}
								type="text"
								id="ap-email"
								name="email"
								maxLength="50"
								onChange={handleChange}
								onBlur={handleChange}
								disabled={submitting}
								placeholder={`${
									dictionary?.["okta-widget-email-placeholder"] || ""
								} *`}
							/>
						</label>
						<span className={styles.note}>
							<RCL searchKey="okta-profile-panel-email-note" />
						</span>
						{verifyConfig.fields.email.hasChanged &&
							displayValidationMessage("email", "email")}
					</div>
				</Cell>
				<Cell>
					<div className={styles.inputContainer}>
						<fieldset className={styles.svgRadio}>
							<div className={styles.checkboxGroup}>
								<input
									checked={verifyConfig.fields.subscribe.value === "true"}
									name="subscribe"
									defaultValue={
										verifyConfig.fields.subscribe.value === "true" ? "" : "true"
									}
									id="ap-subscribe"
									type="checkbox"
									required={false}
									onChange={handleChange}
									disabled={submitting}
								/>
								<label htmlFor="ap-subscribe">
									<RCL searchKey="okta-profile-panel-subscribe-v2" />
								</label>
							</div>
						</fieldset>
						{displayValidationMessage("subscribe", "checkbox")}
					</div>
				</Cell>
				<Cell className={styles.requiredField}>
					{dictionary["indicates-required-field"]}
				</Cell>
			</Row>
			<Row cols={1}>
				<Cell>
					<div className={styles.buttonWrapper}>
						<Button
							className={styles.verifyButton}
							type="submit"
							full
							onClick={submitVerify}
							buttonType="primary"
							disabled={
								submitting ||
								(!verifyConfig.isValid && verifyConfig.hasChanged) ||
								(verifyConfig.isValid && !verifyConfig.hasChanged) ||
								(!verifyConfig.isValid && !verifyConfig.hasChanged)
							}
						>
							{dictionary.verify}
						</Button>
					</div>
				</Cell>
				<Cell className={styles.backButtonContainer}>
					<Button
						className={styles.backButton}
						outline={true}
						noStyle
						type="button"
						onClick={() => {
							setModalOpen(false);
							setVerifyStatus(undefined);
							setWidgetFlow("login");
						}}
					>
						{dictionary["okta-widget-goback-text"]}
					</Button>
				</Cell>
				<Cell>
					<div
						className={styles.legal}
						dangerouslySetInnerHTML={{
							__html: dictionary["okta-widget-legal"],
						}}
					/>
				</Cell>
			</Row>
		</Section>
	);
};

Verify.propTypes = {
	language: PropTypes.oneOf(["en", "fr"]).isRequired,
	dictionary: PropTypes.object.isRequired,
	setWidgetFlow: PropTypes.func.isRequired,
	setModalOpen: PropTypes.func.isRequired,
	setVerifyStatus: PropTypes.func.isRequired,
};

Verify.defaultProps = {};

export default Verify;
export { Verify };
