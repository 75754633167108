import { useContext } from "react";
import { setLanguageCookie } from "../utils/helpers/helper";
import { RCLContext } from "./RCLProvider";

const useLocale = () => {
	const { state, dispatch } = useContext(RCLContext);

	const changeLanguage = lang => {
		setLanguageCookie(lang);
		dispatch({
			type: "UPDATE-LANG",
			payload: { lang },
		});
	};

	return { changeLanguage, lang: state.lang, status: state.status };
};

export default useLocale;
export { useLocale };
