/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Button } from "@sunwing/components";
import styles from "./CookieAcceptanceBanner.module.scss";
import { getCookie, setCookie } from "../utils/helpers/helper";
import { RCL as useTranslation } from "../RCL";

const CookieAcceptanceBanner = () => {
	const bannerText = useTranslation({ searchKey: "cookie-accept-banner-qba" });
	const buttonText = useTranslation({ searchKey: "accept" });

	const [shouldShowBanner, setShouldShowBanner] = useState(false);

	useEffect(() => {
		const cookieAcceptance = getCookie("cookie-acceptance");
		if (!cookieAcceptance) {
			setShouldShowBanner(true);
		}
	}, []);

	const handleClick = () => {
		const expiration = new Date();
		expiration.setUTCMonth(expiration.getMonth() + 1);
		const cookieDomain = window.location.hostname.replace(/^www\./, ".");
		setCookie("cookie-acceptance", true, "/", cookieDomain, expiration);
		setShouldShowBanner(false);
	};

	if (!shouldShowBanner) {
		return null;
	}
	return (
		<div className={styles?.banner}>
			<div className={styles?.bannerContainer}>
				<span className={styles?.text}>{bannerText} </span>
				<Button
					className={styles?.acceptBtn}
					label={buttonText}
					size="sm"
					theme="primary"
					onClick={handleClick}
				/>
			</div>
		</div>
	);
};
export default CookieAcceptanceBanner;
